exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-careers-tsx": () => import("./../../../src/pages/careers.tsx" /* webpackChunkName: "component---src-pages-careers-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-education-tsx": () => import("./../../../src/pages/education.tsx" /* webpackChunkName: "component---src-pages-education-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-interview-hsu-tsx": () => import("./../../../src/pages/interview/hsu.tsx" /* webpackChunkName: "component---src-pages-interview-hsu-tsx" */),
  "component---src-pages-interview-nakahara-tsx": () => import("./../../../src/pages/interview/nakahara.tsx" /* webpackChunkName: "component---src-pages-interview-nakahara-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-recruit-tsx": () => import("./../../../src/pages/recruit.tsx" /* webpackChunkName: "component---src-pages-recruit-tsx" */),
  "component---src-pages-staff-tsx": () => import("./../../../src/pages/staff.tsx" /* webpackChunkName: "component---src-pages-staff-tsx" */),
  "component---src-pages-works-tsx": () => import("./../../../src/pages/works.tsx" /* webpackChunkName: "component---src-pages-works-tsx" */),
  "component---src-templates-work-tsx": () => import("./../../../src/templates/work.tsx" /* webpackChunkName: "component---src-templates-work-tsx" */)
}

